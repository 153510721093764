import { Container, Row } from 'react-bootstrap'
import logo1 from '../assets/img/clien/logo1.svg'
import logo3 from '../assets/img/clien/logo3.svg'
import logo4 from '../assets/img/clien/logo4.svg'
import logo6 from '../assets/img/clien/logo6.svg'
import logo9 from '../assets/img/clien/logo9.svg'
import logo10 from '../assets/img/clien/logo10.svg'
import logo11 from '../assets/img/clien/logo11.svg'

export const Client = () => {
  return (
    <section className='client' id='client'>
      <Container>
        <Row className='text-center'>
          <div className='text-center'>
            <h1 className='our-cln'>Our Clients</h1>
            <img src={logo1} alt='Logo1' className='client-img' />
            <img src={logo3} alt='Logo3' className='client-img' />
            <img src={logo4} alt='Logo4' className='client-img' />
            <img src={logo6} alt='Logo6' className='client-img' />
            <img src={logo9} alt='Logo9' className='client-img' />
            <img src={logo10} alt='Logo10' className='client-img' />
            <img src={logo11} alt='Logo11' className='client-img' />
          </div>
        </Row>
      </Container>
    </section>
  )
}
