import { Container, Row, Col, Nav } from 'react-bootstrap'
import logo2 from '../assets/img/logo2.svg'
import navIcon1 from '../assets/img/nav-icon1.svg'
import navIcon2 from '../assets/img/nav-icon2.svg'
import navIcon3 from '../assets/img/nav-icon3.svg'

export const Footer = () => {
  return (
    <footer className='footer' id='contact'>
      <Container>
        <Col size={12} sm={6} className='flex-container'>
          <img src={logo2} alt='Logo2' className='logo-img' />
          <div className='separator'></div>
          <h1 className='flex-text'>
            PT Lifetech <br /> Tanpa Batas
          </h1>
        </Col>
        <br />
        <div class='separator2'></div>
        <Col size={12} sm={6} className='social'>
          <div className='social-icon'>
            <a href='#'>
              <img src={navIcon1} alt='Icon' />
            </a>
            <a href='#'>
              <img src={navIcon2} alt='Icon' />
            </a>
            <a href='#'>
              <img src={navIcon3} alt='Icon' />
            </a>
          </div>
          <p>(021) 54313924</p>
          <p>+6285890576982</p>
          <p>&copy; Copyright 2024, PT Lifetech Tanpa Batas</p>
        </Col>
      </Container>
      {/* <Nav className='ms-auto'>
        <Nav.Link href='#home'>Home</Nav.Link>
        <Nav.Link href='#about'>About</Nav.Link>
        <Nav.Link href='#services'>Services</Nav.Link>
        <Nav.Link href='#product'>Product</Nav.Link>
        <Nav.Link href='#contact'>Contact</Nav.Link>
      </Nav> */}
    </footer>
  )
}
