import Card from 'react-bootstrap/Card';
import logo_beetpos from '../assets/img/logo_beetpos.png';
import logo1 from '../assets/img/workflow/logo1.svg';
import logo2 from '../assets/img/workflow/logo2.svg';
import logo3 from '../assets/img/workflow/logo3.svg';
import logo4 from '../assets/img/workflow/logo4.svg';

export const Product = () => {
  return (
    <section className='product' id='product'>
      <h2 className='title-workflow'>WorkFlow</h2>
      <div
        className='container'
        style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
      >
        <Card className='product-sx' style={{ width: '15rem' }}>
          <Card.Body>
            <img src={logo2} alt='Logo2' className='workflow-img' />
            <Card.Title>Sketch</Card.Title>
            <Card.Text>
              We will prepare and design the application schema according with
              your vision.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className='product-sx' style={{ width: '15rem' }}>
          <Card.Body>
            <img src={logo1} alt='Logo1' className='workflow-img' />
            <Card.Title>Design</Card.Title>
            <Card.Text>
              All design proccess will be based on the sketch and optimized to
              fulfill everything you need.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className='product-sx' style={{ width: '15rem' }}>
          <Card.Body>
            <img src={logo4} alt='Logo4' className='workflow-img' />
            <Card.Title>Development</Card.Title>
            <Card.Text>
              The development and updates on your application will be our upmost
              priority.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className='product-sx' style={{ width: '15rem' }}>
          <Card.Body>
            <img src={logo3} alt='Logo3' className='workflow-img' />
            <Card.Title>Testing</Card.Title>
            <Card.Text>
              Every segment of the application will be tested before we deliver
              it to you.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className='product-bx'>
          <Card.Body>
            <h2 className='title-product'>Our Product</h2>
            <div className='d-flex flex-column align-items-center justify-content-center'>
              <img src={logo_beetpos} alt='logo_beetpos' className='product-img' />
            </div>
          </Card.Body>
        </Card>
      </div>
    </section>
  );
};
