import { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
// import 'animate.css';
import TrackVisibility from 'react-on-screen'

export const Banner = () => {
  return (
    <section className='banner' id='home'>
      <Container>
        <Row className='text-center'>
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className="text-center"> 
                  <h1>Futuristic Living <br/>With Technology</h1>
                  <p>
                    Whether a mobile, desktop or web application, we will
                    design, build and maintain everything according to your
                    vision.
                  </p>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
