import Card from 'react-bootstrap/Card'
import logolt from '../assets/img/logolt.svg'

export const About = () => {
  return (
    <section className='about' id='about'>
      <div className='container'>
        <div className='row justify-content-center'>
          <Card className='about-bx'>
            <Card.Body>
              <div className='skill-bx wow zoomIn'>
                <div className='content-wrapper'>
                  <div className='left-content'>
                    <h4>About Us</h4>
                    <img src={logolt} alt='Logolt' style={{ width: '90%' }} />
                  </div>
                  <div className='right-content'>
                    <p>
                      Lifetech is an information technology solution company
                      focusing on application development, mobile app
                      development, and system integration.
                      <br />
                      <br />
                      We provide services and solutions that will help your
                      company growth, such as Human Resource & Payroll Systems,
                      Artificial Intelligence Implementation, Search Engine
                      Optimization (SEO), and many more.
                    </p>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </section>
  )
}
