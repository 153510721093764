import { Container, Row } from 'react-bootstrap'
import logo1 from '../assets/img/oursolution/logo1.svg'
import logo2 from '../assets/img/oursolution/logo2.svg'
import logo3 from '../assets/img/oursolution/logo3.svg'
import logo4 from '../assets/img/oursolution/logo4.svg'
import logo5 from '../assets/img/oursolution/logo5.svg'
import logo6 from '../assets/img/oursolution/logo6.svg'
import logo7 from '../assets/img/oursolution/logo7.svg'
import logo8 from '../assets/img/oursolution/logo8.svg'
import logo9 from '../assets/img/oursolution/logo9.svg'
import logo10 from '../assets/img/oursolution/logo10.svg'
import logo11 from '../assets/img/oursolution/logo11.svg'
import logo12 from '../assets/img/oursolution/logo12.svg'

export const Solutions = () => {
  return (
    <section className='solutions' id='solutions'>
      <Container>
        <Row className='text-center'>
          <h1 className='title-sol'>Our Solutions</h1>
          <div className='grp-sol'>
            <div className='solution-item'>
              <img src={logo6} alt='Logo6' className='solution-img' />
              <h5>Human Resources & Payroll System</h5>
            </div>
            <div className='solution-item'>
              <img src={logo8} alt='Logo8' className='solution-img' />
              <h5>Customer Realtionship Management (CRM)</h5>
            </div>
            <div className='solution-item'>
              <img src={logo9} alt='Logo9' className='solution-img' />
              <h5>Search Engine Optimization (SEO)</h5>
            </div>
            <div className='solution-item'>
              <img src={logo5} alt='Logo5' className='solution-img' />
              <h5>Document Management System</h5>
            </div>
            <div className='solution-item'>
              <img src={logo3} alt='Logo3' className='solution-img' />
              <h5 className='h5s'>Information System Product</h5>
            </div>
            <div className='solution-item'>
              <img src={logo12} alt='Logo12' className='solution-img' />
              <h5>Artificial Intelligence Implemention</h5>
            </div>
            <div className='solution-item'>
              <img src={logo1} alt='Logo1' className='solution-imgs' />
              <h5 className='h5r'>Card Management System</h5>
            </div>
            <div className='solution-item'>
              <img src={logo11} alt='Logo11' className='solution-img' />
              <h5 className='h5s'>Switching</h5>
            </div>
            <div className='solution-item'>
              <img src={logo7} alt='Logo7' className='solution-img' />
              <h5 className='h5s'>Multi-Vendor System</h5>
            </div>
            <div className='solution-item'>
              <img src={logo4} alt='Logo4' className='solution-img' />
              <h5 className='h5s'>Risk Management</h5>
            </div>
            <div className='solution-item'>
              <img src={logo2} alt='Logo2' className='solution-imgs' />
              <h5 className='h5r'>Fraud Detection System</h5>
            </div>
            <div className='solution-item'>
              <img src={logo10} alt='Logo10' className='solution-img' />
              <h5 className='h5s'>Digital Engagement</h5>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  )
}
