import Card from 'react-bootstrap/Card'
import logo1 from '../assets/img/srvic/logo1.svg'
import logo2 from '../assets/img/srvic/logo2.svg'
import logo3 from '../assets/img/srvic/logo3.svg'
import logo4 from '../assets/img/srvic/logo4.svg'
import logo5 from '../assets/img/srvic/logo5.svg'

export const Service = () => {
  return (
    <section className='service' id='service'>
      <h2 className='title-service'>Service</h2>
      <div
        className='container'
        style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
      >
        <Card
          className='service-sx'
          style={{ width: '20rem', backgroundColor:'black'}}
        >
          <Card.Body>
            <img src={logo2} alt='Logo2' className='service-img' />
            <div className='service-card'>
            <Card.Title>Mobile Development</Card.Title>
            <Card.Text>
              We will assist you in building mobile application, including Android, iOS or cross platform application.
            </Card.Text>
            </div>
          </Card.Body>
        </Card>
        <Card
          className='service-sx'
          style={{ width: '20rem', backgroundColor:'black' }}
        >
          <Card.Body>
            <img src={logo1} alt='Logo1' className='service-img' />
            <div className='service-card'>
            <Card.Title>Application Development</Card.Title>
            <Card.Text>
              We will provide solutions to all your application development and integration, including desktop and website applications.
            </Card.Text>
            </div>
          </Card.Body>
        </Card>
        <Card
          className='service-sx'
          style={{ width: '20rem', backgroundColor:'black' }}
        >
          <Card.Body>
            <img src={logo5} alt='Logo5' className='service-img' />
            <div className='service-card'>
            <Card.Title>Sistem Integration</Card.Title>
            <Card.Text>
              We will help you integrate the best system for your problems, directly from our company associates.
            </Card.Text>
            </div>
          </Card.Body>
        </Card>
        <br />
        <Card
          className='service-sx'
          style={{ width: '20rem', backgroundColor:'black' }}
        >
          <Card.Body>
            <img src={logo3} alt='Logo4' className='service-img' />
            <div className='service-card'>
            <Card.Title>Project Management</Card.Title>
            <Card.Text>
              We will assist you to achieve the objectives of a particular project, according to agreed criteria and parameters.
            </Card.Text>
            </div>
          </Card.Body>
        </Card>

        <Card
          className='service-sx'
          style={{ width: '20rem', backgroundColor:'black' }}
        >
          <Card.Body>
            <img src={logo4} alt='Logo5' className='service-img' />
            <div className='service-card'>
            <Card.Title>Support & Helpdesk</Card.Title>
            <Card.Text>
              We will provide a helpdesk tool that organizes customer communication to help businesses respond to customer and support them more quickly and effectively.
            </Card.Text>
            </div>
          </Card.Body>
        </Card>
        
      </div>
    </section>
  )
}
