import { Container, Row } from 'react-bootstrap'
import logo1 from '../assets/img/banking/logo1.svg'
import logo2 from '../assets/img/banking/logo2.svg'
import logo3 from '../assets/img/banking/logo3.svg'
import logo4 from '../assets/img/banking/logo4.svg'
import logo5 from '../assets/img/banking/logo5.svg'
import logo6 from '../assets/img/banking/logo6.svg'
import logo7 from '../assets/img/banking/logo7.svg'
import logo8 from '../assets/img/banking/logo8.svg'

export const Banking = () => {
  return (
    <section className='banking' id='banking'>
      <Container>
        <Row className='text-center'>
          <h1 className='title-sol'>Our Banking Solutions</h1>
          <div className='grp-sol'>
            <div className='solution-item'>
              <img src={logo1} alt='Logo6' className='solution-img' />
              <h5 className='h5s'>Core Banking System</h5>
            </div>
            <div className='solution-item'>
              <img src={logo2} alt='Logo8' className='solution-img' />
              <h5 className='h5s'>Wealth Management</h5>
            </div>
            <div className='solution-item'>
              <img src={logo3} alt='Logo8' className='solution-img' />
              <h5 className='h5s'>Anti Money Laundering</h5>
            </div>
            <div className='solution-item'>
              <img src={logo4} alt='Logo9' className='solution-image' />
              <h5 className='h5s'>Treasury & Forex</h5>
            </div>
            <div className='solution-item'>
              <img src={logo5} alt='Logo5' className='solution-img' />
              <h5 className='h5s'>Trade Finance</h5>
            </div>
            <div className='solution-item'>
              <img src={logo6} alt='Logo3' className='solution-img' />
              <h5 className='h5s'>Micro Finance</h5>
            </div>
            <div className='solution-item'>
              <img src={logo7} alt='Logo12' className='solution-img' />
              <h5 className='h5s'>Swift & Remittance</h5>
            </div>
            <div className='solution-item'>
              <img src={logo8} alt='Logo1' className='solution-img' />
              <h5 className='h5s'>Financial Inclusion</h5>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  )
}
