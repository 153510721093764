import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { NavBar } from './components/NavBar'
import { Banner } from './components/Banner'
import { About } from './components/About'
import { Product } from './components/Product'
import { Solutions } from './components/Solutions'
import { Banking } from './components/Banking'
import { Client } from './components/Client'
import { Service } from './components/Service'
// import { Contact } from './components/Contact'
import { Footer } from './components/Footer'

function App () {
  return (
    <div className='App'>
      <NavBar />
      <Banner />
      <About />
      <Service />
      <Product />
      <Solutions />
      <Banking />
      <Client />
      {/* <Contact /> */}
      <Footer />
    </div>
  )
}

export default App
